import '../styles/app.scss';

Array.from(document.querySelectorAll('.toast-button')).forEach(element => {
  const toast = element.parentNode.parentElement;

  element.onclick = () => {
    toast.remove();
  };
});

(function () {
  function decimalAdjust(type, value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }

    value = +value;
    exp = +exp;

    if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }

    value = value.toString().split('e');
    value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
    value = value.toString().split('e');

    return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
  }

  if (!Math.round10) {
    Math.round10 = function (value, exp) {
      return decimalAdjust('round', value, exp);
    };
  }
})();
